@import "../../index.scss";

.Tab {
  &_root {
    font-family: "Open Sans Bold", sans-serif;
    color: var(--whiteColor);
    height: 50px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    border: 0px;

    margin-top: 30px;
    cursor: pointer;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 17px;
    margin-right: 40px;

    transition-property: filter, transform, box-shadow, border, background-color;
    transition-duration: 0.1s;
    margin-bottom: 30px;
    border-bottom: 2px solid transparent;
    &:hover {
      color: var(--accent);
    }
    &.scrolled {
      color: var(--secondaryAccent);
      &:hover {
        color: var(--accent);
      }
      &.special {
        color: var(--whiteColor);
      }
    }
    &.alt {
      color: var(--secondaryAccent);
      justify-content: flex-start;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 30px;
      padding-left: 0;
      &:hover {
        background-color: transparent;
        border-bottom: 2px solid transparent;
        color: var(--accent);
      }
    }
    &.special {
      background-color: var(--secondaryAccent);
      border-radius: 5px;
      margin-left: 20px;
      padding-left: 20px;
      padding-right: 20px;

      &:hover {
        background-color: var(--accent);
        color: var(--whiteColor);
      }
    }
  }

  &_text {
    position: relative;
    &::before {
      width: 1rem;
      position: absolute;
      top: -10px;
      left: 0;
    }
    &.alt {
      color: #0f0f0f;
      font-family: "Open Sans Bold", sans-serif;
    }
  }

  &_nav {
    &.showMenu {
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}

a.isActive > .Tab {
  &_root {
    color: var(--accent);
    &.showMenu {
      border-bottom: 2px solid transparent;
    }
  }
}
