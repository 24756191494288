.CaseStudies {
  &_root {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
  }
  &_content {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    text-align: center;
  }
  &_casesContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    min-height: 400px;
    text-align: center;
  }
  &_cases {
    max-width: 1200px;
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
  }
  &_case {
    width: 250px;
    height: 250px;
    margin: 20px;
  }
}
