.ImageBanner {
  &_root {
    position: relative;
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 640px;
    overflow: hidden;
    h1,
    h2,
    h3,
    h4,
    h5 {
      text-align: center;
      max-width: 1000px;
      text-transform: capitalize;
    }
    &.alt {
      background-color: #f9f9f9;
    }
    &.alt2 {
      background-color: var(--accent);
      color: #ffff;
    }
  }
  &_text {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    color: #ffff;
    p {
      margin-top: 30px;
      margin-left: 10px;
      color: var(--whiteColor);
    }
  }
  &_image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    filter: brightness(0.7);
  }
}

@media screen and (max-width: 1232px) {
  .ImageBanner {
    &_text {
      &.alignRight {
        left: 0;
      }
    }
  }
}
