@import "../../index.scss";

.ArticleList {
  &_root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  &_title {
    margin-left: 7.5px;
    margin-bottom: 20px;
    &::before {
      margin-bottom: 10px;
    }
  }

  &_content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.Article {
  &_articleContainer {
    max-width: 400px;
    flex-basis: 30%;
    flex-grow: 1;
    margin-bottom: 40px;
    margin: 15px;
  }
  &_root {
    border-radius: 5px;
    overflow: hidden;
    height: 400px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    img {
      width: inherit;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
  }
  &_image {
    display: block;
    position: absolute;
    overflow: hidden;
    object-fit: contain;
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      top: 0;
      object-fit: cover;
      width: 100%;
    }
  }
  &_text {
    text-align: left;
    color: var(--whiteColor);
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 40px;
    padding-right: 100px;
    background-color: rgba(0, 0, 0, 0.44);
    & > h4,
    p {
      margin-left: 0;
      margin-bottom: 0;
    }
    & > h4 {
      color: var(--whiteColor);
    }
    & > p {
      color: var(--accent);
    }
  }
  &_label {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    font-family: "Open Sans Medium", sans-serif;
    background-color: var(--secondaryAccent);
    margin-left: -40px;
    padding: 10px;
    padding-left: 40px;
    margin-top: 20px;
    & > p {
      margin: 0;
      color: var(--whiteColor);
    }
  }
  &_location {
    font-family: "Open Sans Bold", sans-serif;
  }
}
