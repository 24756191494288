.PillarInfo {
  &_root {
    padding: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }
  &_content {
    display: flex;
    max-width: 1140px;
    flex: 1;
    flex-wrap: wrap;
  }
  &_pillar {
    animation-timing-function: ease-in-out !important;
    flex: 3;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
    text-align: center;
    opacity: 0;
    & > img {
      width: 50%;
      border: 3px solid var(--secondaryAccent);
      border-radius: 25%;
      padding: 25px;
    }
    & > h5 {
      margin-top: 30px;
    }
    & > p {
      width: 100%;
      text-align: center;
    }
  }
}

@media screen and (max-width: 550px) {
  .PillarInfo {
    &_pillar {
      opacity: 0;
      width: 100%;
    }
    &_content {
      flex-direction: column;
    }
  }
}
