.Banner {
  &_root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      max-width: 1000px;
      width: 100%;
    }
    &.alt {
      background-color: #f9f9f9;
    }
    &.alt2 {
      background-color: var(--secondaryAccent);
      color: #ffff;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: #ffff;
        &::before {
          background-color: #f9f9f9;
        }
      }
    }
    &.alignCenter {
      text-align: center;
    }
    &.marginBottom {
      margin-bottom: 80px;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    padding: 40px;
  }
}
