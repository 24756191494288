@import "Animations.scss";

@font-face {
  font-family: "Open Sans Light";
  src: local("Open Sans"), url(./fonts/OpenSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Medium";
  src: local("Open Sans"), url(./fonts/OpenSans-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Bold";
  src: local("Open Sans"), url(./fonts/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Regular";
  src: local("Open Sans"), url(./fonts/OpenSans-Regular.ttf) format("truetype");
}

* {
  list-style: none;
}

li {
  position: relative;
}

li:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--accent);
  border-radius: 50%;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}

body {
  margin: 0;
  font-family: "Open Sans Light", sans-serif;
  letter-spacing: 2px;
  color: #0f0f0f;
  line-height: 25px;
  overflow-x: hidden !important;
  letter-spacing: 0pt;
  --accent: #ffa500;
  --accentLight: #ffd60a;
  --secondaryAccentDark: #001d3d;
  --secondaryAccent: #24557e;
  --secondaryAccentLight: #003566;

  //Grove group colours
  --electrical: #24557e;
  --automation: #e73a39;
  --group: #727272;
  --compliance: #84c179;

  --grey: #f9f9f9;
  --black: #000814;
  --whiteColor: #ffffff;
}

@mixin ButtonChrome {
  color: #ffffff;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  background-color: var(--secondaryAccent);
  border: 0px solid transparent;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 30px;

  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.2rem;

  transition-property: filter, transform, box-shadow;
  transition-timing-function: ease-in-out;
  transition-duration: 0s;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;

  &:hover {
    background-color: var(--secondaryAccentLight);
    color: #fff;
  }

  &.small {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 28px;
    font-size: 0.9rem;
  }

  &.alt {
    padding: 10px;
    background-color: transparent;
    border: 3px solid #fff;
    &:hover {
      background-color: #fff;
      color: #0f0f0f;
      > .ButtonBehaviour_icon {
        filter: brightness(0) invert(0);
      }
    }
    &.scrolled {
      > .ButtonBehaviour_icon {
        filter: brightness(0) invert(0);
      }
    }
    &.showMenu {
      > .ButtonBehaviour_icon {
        border: 3px solid transparent;
        filter: brightness(0) invert(0);
        &:hover {
          border: 3px solid #0f0f0f;
        }
      }
    }
  }
}

@mixin StripesNotchLarge {
  &::before {
    content: "";
    width: 2rem;
    height: 0.3rem;
    display: block;
    position: relative;
    margin-bottom: 15px;
    background: var(--accentLight);
  }
  &.alt {
    &::before {
      background: linear-gradient(
        to right,
        var(--electrical) 33%,
        var(--compliance) 33% 66%,
        var(--automation) 66% 100%
      );
    }
  }
}

.underNotch {
  position: relative;
  @include StripesNotchLarge;
  &::before {
    width: 50px;
    position: absolute;
    margin-bottom: 0;
    bottom: 0;
    margin-left: 2px;
    left: 0;
  }
}

.aboveNotch {
  position: relative;
  @include StripesNotchLarge;
  &::before {
    width: 50px;
    position: absolute;
    margin-bottom: 0;
    top: 0;
    margin-left: 2px;
    left: 0;
  }
  &.large {
    transform: scale(2);
  }
}

.aboveNotchLarge {
  position: relative;
  @include StripesNotchLarge;
  &::before {
    transform: scale(2);
    width: 50px;
    position: absolute;
    margin-bottom: 0;
    top: -25px;
    margin-left: 2px;
    left: 25px;
  }
}

p {
  > img {
    width: 200px;
    height: 200px;
  }
}

video {
  margin: 0;
}

h1 {
  font-size: 80px;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 0;
  font-family: "Open Sans Bold", sans-serif;
  line-height: 65px;
  &:before {
    margin-bottom: 20px;
  }
}

h2 {
  font-size: 60px;
  line-height: 40px;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Open Sans Bold", sans-serif;
}

h3 {
  font-size: 45px;
  line-height: 40px;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;

  font-family: "Open Sans Bold", sans-serif;
}

h4 {
  color: var(--secondaryAccentDark);
  font-size: 37px;
  line-height: 40px;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Open Sans Medium", sans-serif;
}

h5 {
  font-size: 20px;
  line-height: 40px;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Open Sans Medium", sans-serif;
}

h6 {
  font-size: 13px;
  line-height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Open Sans Medium", sans-serif;
}

p {
  font-size: 19px;
  font-weight: 300;
  color: #0f0f0f;
  font-family: Circular, sans-serif;
}

.label {
  font-family: "Open Sans Regular", sans-serif;
  color: #00355a;
  font-size: 23px;
  margin-top: 20px;
  padding: 0;
  margin-bottom: -17.5px;
  text-transform: uppercase;
}

.altColour {
  color: #00355a;
}

.center {
  width: 100%;
  text-align: center;
  justify-content: center;
}

.spaceBottom {
  margin-bottom: 140px;
}

.alignLeft {
  text-align: left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  letter-spacing: 2px;
}

a:link {
  text-decoration: none;
  color: none;
}

a:visited {
  text-decoration: none;
  color: none;
}

a:hover {
  text-decoration: none;
  color: none;
}

a:active {
  text-decoration: none;
  color: none;
}

/* FORM STYLES */
form label {
  display: block;
}

.contact form p {
  margin: 0;
  color: rgba(74, 86, 96, 1);
}

form input,
form textarea {
  padding: 0.4em 0.8em;
  background: rgba(249, 250, 250, 0.5);
  border: 1px solid rgba(74, 86, 96, 0.1);
  border-radius: 2.5px;
  width: 100%;
  box-sizing: border-box;
  min-height: 35px;
  margin-top: 10px;
}

@media screen and (max-width: 1232px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 22.5px;
  }
  h4 {
    font-size: 20px;
  }
  h6 {
    font-size: 12px;
  }
  p {
    font-size: 18px;
  }
}

@media screen and (max-width: 550px) {
  .aboveNotchLarge {
    &::before {
      transform: scale(1);
      top: 0px;
      left: 0px;
    }
  }
}

.css-0 {
  opacity: 0;
}
