.Contact {
  &_root {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
  }
  &_formContainer {
    width: 700px;
    max-width: 80vw;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
  }
  &_formWrapper {
    border-radius: 5px;
  }
  &_contactForm {
    display: grid;
    grid-gap: 20px;
    grid-template-areas:
      "name"
      "email"
      "company"
      "phone"
      "message";
    > p {
      width: 100%;
    }
  }
  &_requiredField {
    display: flex;
    span {
      color: var(--secondaryAccentDark);
    }
  }
  &_submitButton {
    display: flex;
    justify-content: flex-end;
  }
  &_details {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  &_contentText {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    text-align: center;
  }
  &_contentInner {
    display: flex;
    flex: 1;
    max-width: 1000px;
    flex-wrap: wrap;
  }
  &_contactInfo {
    background-color: var(--secondaryAccent);
    flex: 1;
    color: var(--whiteColor);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px;
    max-height: 540px;
    > h4 {
      color: var(--whiteColor);
    }
  }
  &_infoRow {
    display: flex;
    border: 1px solid var(--whiteColor);
    border-left: 0;
    border-right: 0;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  &_contactDetailsIcon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 30px;
    margin-right: 15px;
    color: var(--accent);
    height: 20px;
  }
  &_location {
    flex: 50%;
    flex: 2;
    min-width: 300px;
  }
}

.contact form button {
  color: #fff;
  width: 90px;
  height: 30px;
  border: 0;
  border-radius: 15px;
}

.submit-button {
  align-self: center;
  justify-self: end;
}

.contact form button:hover,
.contact form button:focus {
  color: #fff;
  outline: 0;
  transition: background-color 0.3s ease-out;
}

.required-field {
  color: rgba(74, 86, 96, 0.75);
  align-self: center;
  justify-self: start;
}

.alert {
  margin-bottom: 1em;
  padding: 10px;
  text-align: center;
  color: #fff;
  border-radius: 2.5px;
  display: none;
}

@media screen and (max-width: 1232px) {
  .Contact {
    &_formContainer {
      width: none;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

/* LARGE SCREENS */

@media (min-width: 500px) {
  #contactForm {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "name company"
      "email phone"
      "message message";
  }

  .name-field {
    grid-area: name;
    grid-column: 1 / 2;
  }

  .email-field {
    grid-area: email;
    grid-column: 1 / 2;
  }

  .company-field {
    grid-area: company;
    grid-column: 2 / 3;
  }

  .phone-field {
    grid-area: phone;
    grid-column: 2 / 3;
  }

  .message-field {
    grid-area: message;
    grid-column: 1 / 3;
  }
}
