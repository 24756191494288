.App {
  position: relative;
  height: 100vh;
  display: flex;
  overflow: auto;
  flex-direction: column;
  overflow-y: scroll;
}

.appBackground {
  pointer-events: none;
  position: fixed;
  top: 0;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  background-color: #ffff;
  // just in case we want a floating image background:
  // background-image: url();
  // background-size: cover;
  // background-attachment: fixed;
}
