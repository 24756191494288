.Footer {
  &_root {
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 1;
    padding-bottom: 40px;
    padding-top: 40px;
    margin-top: auto;
    text-align: center;
  }
  &_content {
    display: flex;
    align-items: center;
    max-width: 800px;
    flex-wrap: wrap;
    padding: 20px;
    & > div {
      flex: 1;
      min-width: 220px;
    }
  }
  &_socials {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    border-right: 3px solid var(--accent);
    padding-right: 20px;
  }
  &_address {
    height: 100%;
    text-align: start;
    margin-left: 20px;
  }
  &_blogo {
    width: 300px;
  }
}

@media screen and (max-width: 550px) {
  .Footer {
    &_socials {
      height: unset;
      align-items: center;
      text-align: center;
      border-right: 0px solid var(--accent);
      padding-right: 0px;
    }
    &_address {
      margin-top: 20px;
      height: unset;
      text-align: center;
      margin-left: 0px;
    }
  }
}
